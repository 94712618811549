import { z } from "zod";
import { MyTimestamp } from "./MyTimestamp.class.js";
import { Clonable, Comparable, Serializable } from "./utils.js";

type Serialized = z.infer<typeof MyDuration.serialized>;
export class MyDuration implements Serializable<Serialized>, Clonable<MyDuration>, Comparable {
  static serialized = z.number();

  constructor(seconds: number) {
    this.seconds = Math.floor(Math.max(0, seconds));
  }

  private seconds: number;

  static fromJSON(seconds: Serialized): MyDuration {
    return new MyDuration(seconds);
  }

  static fromSeconds(seconds: number): MyDuration {
    return new MyDuration(seconds);
  }

  static fromMinutes(minutes: number): MyDuration {
    return new MyDuration(minutes * 60);
  }

  static fromHours(hours: number): MyDuration {
    return new MyDuration(hours * 3600);
  }

  static fromMyTimestamp(start: MyTimestamp, end: MyTimestamp = MyTimestamp.now()): MyDuration {
    return new MyDuration(end.getMyDate().toDate().getTime() - start.getMyDate().toDate().getTime());
  }

  /**
   * Returns the stored duration as a string in the format "hh:mm:ss".
   * @returns The duration as a string.
   */
  toString(): string;

  /**
   * Returns the stored duration as a string with the specified number of parts.
   * @param parts - The number of parts to include (1: hours, 2: hours and minutes, 3: hours, minutes, and seconds).
   * @returns The duration as a string with the specified parts.
   */
  toString(parts: number): string;
  toString(parts?: number): string {
    const hoursStr = this.getHours().toString().padStart(2, "0");
    const minutesStr = this.getMinutes().toString().padStart(2, "0");
    const secondsStr = this.getSeconds().toString().padStart(2, "0");

    switch (parts) {
      case 1:
        return `${hoursStr}`;
      case 2:
        return `${hoursStr}:${minutesStr}`;
      case 3:
      default:
        return `${hoursStr}:${minutesStr}:${secondsStr}`;
    }
  }

  getSeconds(): number {
    return Math.floor(this.seconds % 60);
  }

  getMinutes(): number {
    return Math.floor((this.seconds % 3600) / 60);
  }

  getHours(): number {
    return Math.floor(this.seconds / 3600);
  }

  set(values: { hours?: number; minutes?: number; seconds?: number }): MyDuration {
    const hours = values.hours ?? this.getHours();
    const minutes = values.minutes ?? this.getMinutes();
    const seconds = values.seconds ?? this.getSeconds();

    const totalSeconds = hours * 3600 + minutes * 60 + seconds;
    return new MyDuration(totalSeconds);
  }

  clone(): MyDuration {
    return new MyDuration(this.seconds);
  }

  valueOf(): number {
    return this.seconds;
  }

  toJSON(): Serialized {
    return this.seconds;
  }
}
