import { makePersisted } from "@solid-primitives/storage";
import {
  Locale,
  SUPPORTED_LOCALES,
  chainedTranslator,
  dictionaries,
  flatten,
  setBaseLocale,
  translator,
} from "@mooovex/i18n";
import { createEffect, createResource, createSignal, createMemo } from "solid-js";

export * from "@mooovex/i18n";

const detectedLanguage = navigator.language.slice(0, 2).toLowerCase() as Locale;

export const [currentLocale, setLocale] = makePersisted(
  createSignal<Locale>(SUPPORTED_LOCALES.includes(detectedLanguage) ? detectedLanguage : "en"),
  {
    name: "mooovex:preferredLocale",
  }
);

const flattenedDictionaryResource = createMemo(() => flatten(dictionaries[currentLocale()]));

createEffect(() => {
  setBaseLocale(currentLocale()!);
});

export const t = translator(flattenedDictionaryResource);
export const ct = chainedTranslator(flattenedDictionaryResource(), t);
