import { Shared } from "@mooovex/api-schema";
import { Component, Show, mergeProps } from "solid-js";
import classes from "../map/MapDriverMarker.module.scss";
type Props = {
  value?: string;
  status?: Shared.Driverstatus.DriverStatus;
};

export const IdentifierBadge: Component<Props> = (componentProps) => {
  const props = mergeProps({ value: "", status: "outofservice" }, componentProps);
  return (
    <div class={`${classes.badge} ${classes[props.status]} flex-shrink-0`}>
      <Show when={props.value} fallback={<div class="spinner-border spinner-border-sm" />}>
        {props.value}
      </Show>
    </div>
  );
};
