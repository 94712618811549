import { MyTimestamp } from "@mooovex/helpers";
import { createTimeDifferenceFromNow } from "@solid-primitives/date";
import { Component } from "solid-js";
import { Driverlocation } from "../DriverlocationsProvider";
import { ct } from "../i18n";
import { IdentifierBadge, LicensePlate } from "../vehicles";
import PhoneIcon from "lucide-solid/icons/phone";

type Props = {
  driverlocation: Driverlocation;
};

export const MapDriverMarkerPopup: Component<Props> = (props) => {
  const formattedSpeed = () => ct.formatters.kmh(props.driverlocation.speed * 3.6);
  const timestampMs = () =>
    props.driverlocation.timestamp
      ? MyTimestamp.fromJSON(props.driverlocation.timestamp).toDate().valueOf()
      : Date.now();
  const [timestampDiffMs] = createTimeDifferenceFromNow(timestampMs, 1000);
  const formattedRelativeTime = () => ct.formatters.relativeTime(timestampDiffMs());

  return (
    <div class="fs-6 d-flex flex-column gap-1 placeholder-glow">
      <div class="d-flex align-items-center justify-content-between gap-2 nowrap">
        <IdentifierBadge value={props.driverlocation.vehicleIdentifier} status={props.driverlocation.status} />
        <div class="d-flex flex-column" style={{ "min-width": "0" }}>
          <div class="fw-bold text-truncate">{props.driverlocation.driverName}</div>
          <div class="text-muted text-truncate">{props.driverlocation.transportcompanyName}</div>
        </div>
        <button
          class="btn btn-light p-1 lh-1"
          title={`${props.driverlocation.phoneNumber} (${ct["common.rightClickToCopy"]()})`}
          onclick={() => window.open(`tel:${props.driverlocation.phoneNumber}`)}
          oncontextmenu={async (e) => {
            e.preventDefault();
            await navigator.clipboard.writeText(props.driverlocation.phoneNumber);
          }}
        >
          <PhoneIcon class="text-primary" />
        </button>
      </div>
      <div class="d-flex justify-content-between align-items-center gap-2 nowrap">
        <div class="text-truncate">{props.driverlocation.vehicleName}</div>
        <LicensePlate value={props.driverlocation.vehicleLicensePlate ?? undefined} />
      </div>
      <div class="d-flex justify-content-between align-items-center gap-2 nowrap">
        <div>{formattedSpeed()}</div>
        <div class="text-muted">{formattedRelativeTime()}</div>
      </div>
    </div>
  );
};
