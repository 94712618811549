import { createSignal } from "solid-js";
import type { Enums } from "@mooovex/supabase";
import { supabase, userSignal } from ".";

export const [currentRoles, setCurrentRoles] = createSignal<Enums<"role">[]>([]);

export async function fetchRolesInTransportcompany(transportcompany_id: number) {
  const user = userSignal();
  if (!user) throw new Error("User not logged in");
  const { data, error } = await supabase
    .from("transportcompanies_staff_roles")
    .select("roles")
    .eq("transportcompany_id", transportcompany_id)
    .eq("staff_id", user.id)
    .maybeSingle();
  if (error) throw error;

  setCurrentRoles(data?.roles ?? []);

  console.log("roles", data?.roles ?? []);

  return data?.roles ?? [];
}
