import { AuthChangeEvent, createClient, Session, SupabaseClient } from "@supabase/supabase-js";

import { createSignal } from "solid-js";
import { wkx, Buffer } from "@mooovex/wkx";
import type { Database } from "@mooovex/supabase";
import { setUserSignal } from "./user";

export let supabase: SupabaseClient<Database>;

const [sessionInitialized, setSessionInitialized] = createSignal(false);

type MyAuthChangeEvent = AuthChangeEvent | "*";

const eventMap: { [key in MyAuthChangeEvent]: Set<(session: Session | null) => void> } = {
  INITIAL_SESSION: new Set(),
  PASSWORD_RECOVERY: new Set(),
  SIGNED_IN: new Set(),
  SIGNED_OUT: new Set(),
  TOKEN_REFRESHED: new Set(),
  USER_UPDATED: new Set(),
  MFA_CHALLENGE_VERIFIED: new Set(),
  "*": new Set(),
};

export function initSupabase(...options: Parameters<typeof createClient>) {
  supabase = createClient(...options);

  supabase.auth.onAuthStateChange((event, session) => {
    setUserSignal(session?.user);
    console.log(event);

    if (event === "INITIAL_SESSION") {
      setSessionInitialized(true);
    }

    eventMap[event].forEach((handler) => handler(session));
    eventMap["*"].forEach((handler) => handler(session));
  });
}

export function addAuthListener(event: MyAuthChangeEvent, handler: (session: Session | null) => void) {
  eventMap[event].add(handler);
}

export function removeAuthListener(event: MyAuthChangeEvent, handler?: (session: Session | null) => void) {
  if (handler) {
    eventMap[event].delete(handler);
  } else {
    eventMap[event].clear();
  }
}

export async function getSession() {
  const { data, error } = await supabase.auth.getSession();
  if (error) throw error;
  return data.session;
}

export function toGeoJSON(wkb: any) {
  return wkx.Geometry.parse(Buffer.from(wkb, "hex")).toGeoJSON() as any;
}

export function toWkb(geojson: any) {
  return wkx.Geometry.parseGeoJSON(geojson).toWkb().toString("hex");
}

export { sessionInitialized };

export * from "./device";
export * from "./drivers";
export * from "./live_locations";
export * from "./roles";
export * from "./staff";
export * from "./storage";
export * from "./transportcompanies";
export * from "./user";
export * from "./vehicles";
