import * as i18n from "@solid-primitives/i18n";
import { dict as de } from "./de.js";
import { Dict, dict as en } from "./en.js";
import { dict as it } from "./it.js";
export * from "@solid-primitives/i18n";

export const dictionaries = { en, de, it };
export let CURRENT_LOCALE: Locale = "en";
export const SUPPORTED_LOCALES = ["en", "de", "it"] as const;
export type Locale = (typeof SUPPORTED_LOCALES)[number];
export type Dictionary = i18n.Flatten<Dict>;

export function d(locale?: Locale) {
  return dictionaries[locale ?? CURRENT_LOCALE];
}

export function setBaseLocale(locale: Locale) {
  CURRENT_LOCALE = locale;
}
