import { z } from "./openapizod.js";
import * as PlaceAdapter from "./placeAdapter.shared.api.schema.js";
import * as Shared from "./shared.api.schema.js";

export const config = z
  .object({
    onlyPriceCalculator: z.boolean().optional(),
    allowedUrls: z.array(z.string()),
    layout: z.enum(["opacity-light", "opacity-dark", "full-height-light", "full-height-dark"]),
    map: z.object({
      center: Shared.GeoJson.coordinates,
      zoomlevel: z.number(),
    }),
    intro: z
      .object({
        imageUrl: z.string().or(z.record(z.enum(["desktop", "mobile"]), z.string())),
        content: z.string().or(z.record(z.enum(["desktop", "mobile"]), z.string())),
        position: z
          .enum(["right_top", "right_bottom", "left_bottom"])
          .or(z.record(z.enum(["desktop", "mobile"]), z.enum(["right_top", "right_bottom", "left_bottom"]))),
      })
      .optional(),
    booking: z.object({
      action: z.enum(["request", "payment"]),
      defaultOrigin: PlaceAdapter.place.optional(),
      defaultDestination: PlaceAdapter.place.optional(),
      timeOffsetMinutes: z
        .object({
          min: z.number().nonnegative().optional(),
          max: z.number().nonnegative().optional(),
        })
        .optional(),
      routeDurationMinutes: z
        .object({
          min: z.number().nonnegative().optional(),
          max: z.number().nonnegative().optional(),
        })
        .optional(),
      routeDistanceMeters: z
        .object({
          min: z.number().nonnegative().optional(),
          max: z.number().nonnegative().optional(),
        })
        .optional(),
      passengerCount: z.object({
        min: z.number().int().nonnegative(),
        max: z.number().int().nonnegative(),
        default: z.number().int().nonnegative().optional(),
      }),
      shortTransfer: z
        .object({
          minutes: z.number().nonnegative(),
          fields: z.array(z.string()),
        })
        .optional(),
      maxContactHours: z.number().int().positive().optional(),
    }),
    company: z
      .object({
        name: z.string().min(1),
        address: z.string().min(1),
        addressComponents: z
          .object({
            street: z.string().min(1),
            housenumber: z.string().min(1),
            postcode: z.string().min(1),
            city: z.string().min(1),
            state: z.string().min(1),
            country: z.string().min(1),
            countrycode: z.string().min(1),
          })
          .optional(),
        phone: Shared.phoneNumber,
        email: z.string().email(),
        vatId: z.string().min(11),
      })
      .optional(),
    header: z.object({
      logoUrl: z.string().url(),
    }),
    disableLanguageSelector: z.boolean(),
    smtp: z
      .object({
        host: z.string(),
        port: z.number().int().min(0).max(65535),
        username: z.string(),
        password: z.string(),
        fromEmail: z.string().email(),
        encryption: z.enum(["ssl", "tls"]).nullable(),
      })
      .nullable(),
  })
  .openapi("OnlinebookingConfig");

export namespace Get {
  export const responseBody = z.object({
    id: z.string(),
    created_at: z.string(),
    config: config,
  });

  export type ResponseBody = z.infer<typeof responseBody>;

  export const requestParams = z.object({
    onlinebooking_config_id: z.string(),
  });

  export type RequestParams = z.infer<typeof requestParams>;
}
