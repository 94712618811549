import { z } from "./openapizod.js";

export const responseBody = z.object({
  id: z.string(),
  created_at: z.string(),
  onlinebooking_id: z.string(),
  ip_address: z.string().ip().optional(),
});

export type ResponseBody = z.infer<typeof responseBody>;

export const requestParams = z.object({
  onlinebooking_config_id: z.string(),
  onlinebooking_id: z.string(),
});

export type RequestParams = z.infer<typeof requestParams>;

export const requestBody = z
  .object({
    ip_address: z.string().ip().optional(),
  })
  .optional();

export type RequestBody = z.infer<typeof requestBody>;
