import { ParentComponent } from "solid-js";
import { Portal } from "solid-js/web";
import { useSolidMap } from "@mooovex/solid-map";

type Props = {
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
};

export const MapControlGroup: ParentComponent<Props> = (props) => {
  const map = useSolidMap();

  return (
    <Portal mount={map.getContainer()}>
      <div
        class="d-flex flex-column gap-2"
        style={{
          position: "absolute",
          top: props.top && props.top + "px",
          right: props.right && props.right + "px",
          bottom: props.bottom && props.bottom + "px",
          left: props.left && props.left + "px",
          "z-index": 50,
        }}
      >
        {props.children}
      </div>
    </Portal>
  );
};
