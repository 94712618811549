import { z } from "./openapizod.js";
import * as Shared from "./shared.api.schema.js";

export const requestBody = z.object({
  google_place_id: z.string(),
  language: z.enum(["de", "en", "it"]).optional().default("it"),
});

export type RequestBody = z.infer<typeof requestBody>;

export const responseBody = z.object({
  google_place_id: z.string(),
  name: z.string(),
  formatted_address: z.string(),
  location: Shared.GeoJson.point,
  types: z.array(z.string()),
  city: z.string().optional(),
  country: z.string().optional(),
  countrycode: z.string().optional(),
  postcode: z.string().optional(),
  state: z.string().optional(),
  street: z.string().optional(),
  housenumber: z.string().optional(),
});

export type ResponseBody = z.infer<typeof responseBody>;
