import { setLocale } from "@mooovex/components";
import { ParentComponent, batch, createEffect, on, onCleanup, onMount } from "solid-js";
import {
  fetchMyStaff,
  fetchRolesInTransportcompany,
  fetchTransportcompanies,
  setAvailableTransportcompanyIds,
  setCurrentRoles,
} from "@mooovex/supabase-client";
import { selectedTransportcompanyId, setSelectedTransportcompanyId } from "../services/state/transportcompanies.state";
import NavbarC from "./Navbar.c";

export const NavbarWrapperC: ParentComponent = (props) => {
  onMount(async () => {
    await batch(async () => {
      const staff = await fetchMyStaff();
      if (staff) {
        setLocale(staff.locale);
      }
      const transportcompanies = await fetchTransportcompanies();
      if (transportcompanies.length === 1) {
        setSelectedTransportcompanyId(transportcompanies[0].id);
      }
    });
  });

  onCleanup(() => {
    batch(() => {
      setAvailableTransportcompanyIds([]);
    });
  });

  createEffect(
    on(selectedTransportcompanyId, async (tcid) => {
      if (tcid) {
        await fetchRolesInTransportcompany(tcid);
      } else {
        setCurrentRoles([]);
      }
    })
  );

  return (
    <>
      <NavbarC />
      <div
        class="overflow-hidden"
        style={{
          position: "relative",
          height: "100%",
        }}
      >
        {props.children}
      </div>
    </>
  );
};

export default NavbarWrapperC;
