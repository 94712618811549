import hd, { HumanizerOptions } from "humanize-duration";
import { Locale } from "./index.js";

export function kmh(locale: Locale) {
  return new Intl.NumberFormat(locale, {
    style: "unit",
    maximumFractionDigits: 1,
    unit: "kilometer-per-hour",
  }).format;
}

export function mps(locale: Locale) {
  return new Intl.NumberFormat(locale, {
    style: "unit",
    maximumFractionDigits: 1,
    unit: "meter-per-second",
  }).format;
}

export function humanDuration(locale: Locale, options?: HumanizerOptions) {
  return (ms: number) => hd(ms, { units: ["d", "h", "m"], largest: 2, round: true, language: locale, ...options });
}

export function duration(ms: number) {
  let seconds = Math.floor(ms / 1000);
  let hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  let minutes = Math.floor(seconds / 60);
  seconds %= 60;
  return [hours, minutes, seconds].map(padZero).join(":");
}

function padZero(num: number) {
  return (num < 10 ? "0" : "") + num;
}

export function kilometers(locale: Locale) {
  return new Intl.NumberFormat(locale, {
    style: "unit",
    maximumFractionDigits: 1,
    unit: "kilometer",
  }).format;
}

export function meters(locale: Locale) {
  return new Intl.NumberFormat(locale, {
    style: "unit",
    maximumFractionDigits: 1,
    unit: "meter",
  }).format;
}

export function euros(locale: Locale) {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: "EUR",
  }).format;
}

export function relativeTime(locale: Locale) {
  return (ms: number) => {
    const seconds = ms < 0 ? Math.ceil(ms / 1000) : Math.floor(ms / 1000);
    const minutes = ms < 0 ? Math.ceil(seconds / 60) : Math.floor(seconds / 60);
    const hours = ms < 0 ? Math.ceil(minutes / 60) : Math.floor(minutes / 60);
    const days = ms < 0 ? Math.ceil(hours / 24) : Math.floor(hours / 24);

    const rtf = new Intl.RelativeTimeFormat(locale, { numeric: "auto", style: "narrow" });

    if (days !== 0) {
      return rtf.format(days, "day");
    } else if (hours !== 0) {
      return rtf.format(hours, "hour");
    } else if (minutes !== 0) {
      return rtf.format(minutes, "minute");
    } else {
      return rtf.format(seconds, "second");
    }
  };
}

export function date(locale: Locale) {
  return new Intl.DateTimeFormat(locale, { dateStyle: "medium" }).format;
}

export function time(locale: Locale) {
  return new Intl.DateTimeFormat(locale, { timeStyle: "short" }).format;
}

export function percent(locale: Locale) {
  return new Intl.NumberFormat(locale, { style: "percent", signDisplay: "exceptZero" }).format;
}
