import { Component, ParentProps } from "solid-js";
import logoUrl from "@mooovex/assets/images/mooovex_logo.svg";
import classes from "./FormCard.module.scss";

export const FormCard: Component<ParentProps> = (props) => {
  return (
    <div class="row justify-content-center g-0 mt-auto mb-auto ps-3 pe-3">
      <div class="col-xl-4 col-lg-5 col-md-6 col-sm-7 card">
        <div class={`card-body p-5 ${classes.logo}`}>
          <img src={logoUrl} class="card-img-top" alt="logo" />
        </div>
        <div class="card-body">{props.children}</div>
      </div>
    </div>
  );
};
