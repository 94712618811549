import { SolidMapMarker, useSolidMap } from "@mooovex/solid-map";
import { Marker } from "maplibre-gl";
import { ParentComponent, Show, createEffect, onCleanup } from "solid-js";
import { Driverlocation } from "../DriverlocationsProvider";
import classes from "./MapDriverMarker.module.scss";
import { MapDriverMarkerPopup } from "./MapDriverMarkerPopup";
type Props = {
  driverlocation: Driverlocation;
  follow?: boolean;
  markerSizeMultiplier?: number;
};

function DriverMarkerIcon(props: { driverlocation: Driverlocation; driverstatusClassName: string | null }) {
  return (
    <>
      <div class={`${classes.icon} ${props.driverstatusClassName}`}>{props.driverlocation.vehicleIdentifier}</div>
      <Show
        when={
          typeof props.driverlocation.heading === "number" &&
          props.driverlocation.heading !== -1 &&
          props.driverlocation.speed * 3.6 >= 10
        }
      >
        <div
          class={classes.direction}
          style={{
            transform: `rotate(${props.driverlocation.heading}deg)`,
          }}
        >
          <div class={`${classes.arrow} ${props.driverstatusClassName}`} />
        </div>
      </Show>
    </>
  );
}

export const driverMarkerInstances = new Map<string, Marker>();

export const MapDriverMarker: ParentComponent<Props> = (props) => {
  const driverstatusClass = () => classes[props.driverlocation.status];

  const map = useSolidMap();

  createEffect(() => {
    if (props.follow) {
      const zoomlevel = calculateZoomLevel(
        props.driverlocation.speed * 30,
        props.driverlocation.point[1],
        map.getContainer().clientWidth
      );
      map.flyTo({ center: props.driverlocation.point, zoom: Math.min(zoomlevel, 18) });
    }
  });

  onCleanup(() => {
    driverMarkerInstances.delete(props.driverlocation.driverId);
  });

  return (
    <SolidMapMarker
      lngLat={props.driverlocation.point}
      onInit={(marker) => {
        driverMarkerInstances.set(props.driverlocation.driverId, marker);
      }}
      popupOptions={{
        closeButton: true,
        closeOnMove: false,
        closeOnClick: true,
        anchor: "bottom",
        focusAfterOpen: false,
        offset: [0, -13 * (props.markerSizeMultiplier ?? 1)],
        maxWidth: "300px",
      }}
      class={`${classes.marker} ${driverstatusClass()}`}
      popupElement={<MapDriverMarkerPopup driverlocation={props.driverlocation} />}
    >
      <DriverMarkerIcon driverlocation={props.driverlocation} driverstatusClassName={driverstatusClass()} />
    </SolidMapMarker>
  );
};

function calculateZoomLevel(widthMeters: number, latitude: number, mapWidth: number): number {
  // Constants
  const earthCircumference = 40075016.686; // Equatorial circumference of the Earth in meters
  const tileSize = 512; // Tile size in pixels

  // Calculate the zoom level
  const zoomLevel = Math.log2(
    (earthCircumference * Math.cos(latitude * (Math.PI / 180))) / ((widthMeters / mapWidth) * tileSize)
  );

  return zoomLevel;
}
