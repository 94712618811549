import { createSelector } from "solid-js";
import { transportcompaniesCache } from "@mooovex/supabase-client";
import { createUserStorageSignal } from "@mooovex/components";

export const [selectedTransportcompanyId, setSelectedTransportcompanyId] = createUserStorageSignal<number | undefined>(
  "transportcompany.selected.id"
);

export const selectedTransportcompany = () => transportcompaniesCache.get(selectedTransportcompanyId()!);
export const isSelectedTransportcompany = createSelector(
  selectedTransportcompany,
  (a, b) => !!a && !!b && a.id === b.id
);
