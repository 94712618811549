import { createSortable, transformStyle } from "@thisbeyond/solid-dnd";
import { MyDate, MyTime } from "@mooovex/helpers";
import * as API from "@mooovex/api-schema";
import { Component, For } from "solid-js";
import { destinations } from "../../services/state/newpricelist.state";
import PlaceTD from "./PlaceTD";
import RouteTD from "./RouteTD";

type Props = {
  origin: API.google_place_details.ResponseBody;
  dateGetter: () => MyDate | undefined;
  timeGetter: () => MyTime | undefined;
};

export const TR: Component<Props> = (props) => {
  const originId = API.PlaceAdapter.getId(props.origin);
  const sortable = createSortable(originId);
  return (
    <>
      <tr ref={sortable.ref} style={transformStyle(sortable.transform)}>
        <PlaceTD sortable={sortable} place={props.origin} type="origin" />
        <For each={destinations()}>
          {(destination) => (
            <>
              <RouteTD
                {...{ origin: props.origin, destination, dateGetter: props.dateGetter, timeGetter: props.timeGetter }}
              />
            </>
          )}
        </For>
      </tr>
    </>
  );
};
