import { z } from "./openapizod.js";
import * as price from "./price.api.schema.js";
import * as Shared from "./shared.api.schema.js";
import * as vehicleproducts from "./vehicleproducts.api.schema.js";

export const chosenVehicleExtra = z.object({
  path: z.array(vehicleproducts.vehicleExtraGroupName.or(vehicleproducts.vehicleExtraName)).min(1),
  count: z.number().int().nonnegative(),
});

export type ChosenVehicleExtra = z.infer<typeof chosenVehicleExtra>;

export const responseBody = z.object({
  price: z.number().nonnegative(),
  extraPersonPrice: z.number().nonnegative(),
  pricePerPerson: z.number().nonnegative(),
  isBooking: z.boolean(),
  exceptionReasons: z.array(Shared.i18nValue),
  vatRate: z.number().min(0),
  netPrice: z.number().nonnegative(),
});
export type ResponseBody = z.infer<typeof responseBody>;

export const requestBody = z.object({
  route_id: z.string(),
  passengerCount: z.number().int().nonnegative(),
  when: z.object({ date: Shared.date, time: Shared.time }).or(z.literal("now")),
  discount: z.number().min(-1).optional(),
  vehicle_id: z.string().optional(),
  extras: z.array(price.chosenVehicleExtra).optional(),
});

export type RequestBody = z.infer<typeof requestBody>;

export const requestParams = z.object({
  onlinebooking_config_id: z.string(),
});
export type RequestParams = z.infer<typeof requestParams>;
