import { MyTimestamp } from "@mooovex/helpers";
import { Shared } from "@mooovex/api-schema";
import { ApiClientError, client } from "./client";

export async function compute(
  coordinates: Shared.GeoJson.Coordinates[],
  timestamp: MyTimestamp,
  transportcompanyId?: number,
  withReturnRoute = false
) {
  const response = await client.v1.route.$post({
    json: {
      coordinates: coordinates.map((c) => [c[0], c[1]]),
      when: {
        date: timestamp.getMyDate().toJSON(),
        time: timestamp.getMyTime().toJSON(),
      },
      transportcompanyId: transportcompanyId,
      with_return_route: withReturnRoute,
    },
  });

  if (!response.ok) {
    throw new ApiClientError("Route Computation failed", response);
  }

  return await response.json();
}

export async function get(id: string | number) {
  const response = await client.v1.route[":id"].$post({
    param: {
      id: id.toString(),
    },
  });

  if (!response.ok) {
    throw new ApiClientError("Route Get failed", response);
  }

  return await response.json();
}
