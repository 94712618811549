import { Buffer, wkx } from "@mooovex/wkx";
import { MyCoordinates3D } from "./MyCoordinates3D.class.js";
import { MyLineString2D } from "./MyLineString2D.class.js";
import { Clonable, Serializable } from "./utils.js";

export class MyLineString3D implements Serializable<[number, number, number][]>, Clonable<MyLineString3D> {
  static serialized = MyCoordinates3D.serialized.array().min(2);

  constructor(public points: MyCoordinates3D[]) {}

  static fromJSON(value: [number, number, number][]): MyLineString3D {
    return new MyLineString3D(value.map((v) => MyCoordinates3D.fromJSON(v)));
  }

  static fromGeoJSON(value: { type: "LineString"; coordinates: [number, number, number][] }): MyLineString3D {
    return this.fromJSON(value.coordinates);
  }

  static fromMyLineString2D(value: MyLineString2D, alt: number = 0) {
    return new MyLineString3D(value.points.map((p) => MyCoordinates3D.fromMyCoordinates2D(p, alt)));
  }

  static fromWkx(value: wkx.LineString) {
    if (!value.hasZ) {
      MyLineString2D.fromWkx(value);
    }

    return new MyLineString3D(value.points.map((p) => MyCoordinates3D.fromWkx(p)));
  }

  static fromWkb(value: Buffer | string) {
    if (typeof value === "string") value = Buffer.from(value, "hex");
    return MyLineString3D.fromWkx(wkx.Geometry.parse(value) as wkx.LineString);
  }

  toWkb() {
    return this.toWkx().toWkb();
  }

  toGeoJSON() {
    return {
      type: "LineString",
      coordinates: this.toJSON(),
    } as const;
  }

  clone(): MyLineString3D {
    return new MyLineString3D(this.points);
  }

  toJSON(): [number, number, number][] {
    return this.points.map((p) => p.toJSON());
  }

  toString() {
    return JSON.stringify(this.toJSON());
  }

  toMyLinestring2D() {
    return MyLineString2D.fromMyLineString3D(this);
  }

  toWkx() {
    return new wkx.LineString(this.points.map((p) => p.toWkx()));
  }

  get length() {
    return this.points.length;
  }

  getSmoothedElevation(windowSize = 3) {
    let ascend = 0;
    let descend = 0;

    const smoothed = smooth(
      this.points.map((p) => p.alt),
      windowSize
    );

    for (let i = 1; i < smoothed.length; i++) {
      const prevHeight = smoothed[i - 1];
      const currentHeight = smoothed[i];
      const diff = currentHeight - prevHeight;
      if (diff > 0) {
        ascend += diff;
      } else {
        descend -= diff;
      }
    }

    return { ascend, descend };
  }

  /**
   * Calculates the bounding box of the 3D linestring.
   *
   * @returns A tuple containing two points:
   *          - The first point represents the minimum corner (min lng, lat, alt)
   *          - The second point represents the maximum corner (max lng, lat, alt)
   * @throws {Error} If the linestring is empty
   */
  getBounds(): [MyCoordinates3D, MyCoordinates3D] {
    if (this.points.length === 0) {
      throw new Error("Cannot calculate bounds of an empty linestring");
    }

    let minLng = this.points[0].lng;
    let minLat = this.points[0].lat;
    let minAlt = this.points[0].alt;
    let maxLng = this.points[0].lng;
    let maxLat = this.points[0].lat;
    let maxAlt = this.points[0].alt;

    for (let i = 1; i < this.points.length; i++) {
      const point = this.points[i];
      minLng = Math.min(minLng, point.lng);
      minLat = Math.min(minLat, point.lat);
      minAlt = Math.min(minAlt, point.alt);
      maxLng = Math.max(maxLng, point.lng);
      maxLat = Math.max(maxLat, point.lat);
      maxAlt = Math.max(maxAlt, point.alt);
    }

    return [
      new MyCoordinates3D(minLng, minLat, minAlt), // Southwest corner
      new MyCoordinates3D(maxLng, maxLat, maxAlt), // Northeast corner
    ];
  }
}

function smooth(arr: number[], windowSize: number) {
  const result = [];

  for (let i = 0; i < arr.length; i += 1) {
    const leftOffset = i - windowSize;
    const from = leftOffset >= 0 ? leftOffset : 0;
    const to = i + windowSize + 1;

    let count = 0;
    let sum = 0;
    for (let j = from; j < to && j < arr.length; j += 1) {
      sum += arr[j];
      count += 1;
    }

    result[i] = sum / count;
  }

  return result;
}
