import { useNavigate, useSearchParams } from "@solidjs/router";
import { FormCard, LoginForm, ct, lastUsedEmail } from "@mooovex/components";
import { Component, createEffect } from "solid-js";
import { signInWithPassword } from "@mooovex/supabase-client";

const LoginPage: Component = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams<{ confirmEmail?: string; accountMarkedForDeletion?: string }>();

  createEffect(() => {
    if (searchParams.confirmEmail) {
      alert(ct.account.created.message(searchParams.confirmEmail));
    }

    if (searchParams.accountMarkedForDeletion) {
      alert(ct.account.delete.confirmation.message());
    }
  });

  return (
    <FormCard>
      <LoginForm
        onSubmit={async (credentials) => {
          await signInWithPassword(credentials);
          navigate("/");
        }}
        registerHref="/register"
        resetPasswordHref="/resetPassword"
        email={searchParams.confirmEmail ?? lastUsedEmail()}
      />
    </FormCard>
  );
};

export default LoginPage;
