import { Shared } from "@mooovex/api-schema";
import { setLocaleRequest } from "@mooovex/supabase-client";
import { Component, For, createSignal } from "solid-js";
import { SUPPORTED_LOCALES, currentLocale, setLocale } from "./i18n";

type Props = {
  onError?: (error: unknown) => void;
};

export const LocaleSelectorDropdown: Component<Props> = (props) => {
  const [loading, setLoading] = createSignal(false);

  async function changeLocale(locale: Shared.Locale) {
    setLoading(true);
    try {
      const updatedLocale = await setLocaleRequest(locale);
      setLocale(updatedLocale);
    } catch (error) {
      props.onError?.(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <div class="dropdown">
        <button class="btn btn-light dropdown-toggle" data-bs-toggle="dropdown" disabled={loading()}>
          <i class="bi-globe me-2" />
          {currentLocale().toUpperCase()}
        </button>
        <div class="dropdown-menu dropdown-menu-end">
          <For each={SUPPORTED_LOCALES}>
            {(locale) => (
              <button
                class="dropdown-item"
                onclick={() => changeLocale(locale)}
                classList={{ active: currentLocale() === locale }}
              >
                {locale.toUpperCase()}
              </button>
            )}
          </For>
        </div>
      </div>
    </>
  );
};
