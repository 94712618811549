import { Buffer, wkx } from "@mooovex/wkx";
import { MyCoordinates2D } from "./MyCoordinates2D.class.js";
import { MyCoordinates3D } from "./MyCoordinates3D.class.js";
import { MyLineString3D } from "./MyLineString3D.class.js";
import { Clonable, Serializable } from "./utils.js";

export class MyLineString2D implements Serializable<[number, number][]>, Clonable<MyLineString2D> {
  static serialized = MyCoordinates2D.serialized.array().min(2);

  constructor(public points: MyCoordinates2D[]) {}

  static fromJSON(value: [number, number][]): MyLineString2D {
    return new MyLineString2D(value.map((v) => MyCoordinates2D.fromJSON(v)));
  }

  static fromGeoJSON(value: { type: "LineString"; coordinates: [number, number][] }): MyLineString2D {
    return this.fromJSON(value.coordinates);
  }

  static fromMyLineString3D(value: MyLineString3D) {
    return new MyLineString2D(value.points.map((p) => MyCoordinates2D.fromMyCoordinates3D(p)));
  }

  static fromWkx(value: wkx.LineString) {
    if (value.hasZ) {
      MyLineString3D.fromWkx(value);
    }

    return new MyLineString2D(value.points.map((p) => MyCoordinates2D.fromWkx(p)));
  }

  static fromWkb(value: Buffer | string) {
    if (typeof value === "string") value = Buffer.from(value, "hex");
    return MyLineString2D.fromWkx(wkx.Geometry.parse(value) as wkx.LineString);
  }

  toWkb() {
    return this.toWkx().toWkb();
  }

  toGeoJSON() {
    return {
      type: "LineString",
      coordinates: this.toJSON(),
    } as const;
  }

  clone(): MyLineString2D {
    return new MyLineString2D(this.points);
  }

  toJSON(): [number, number][] {
    return this.points.map((p) => p.toJSON());
  }

  toString() {
    return JSON.stringify(this.toJSON());
  }

  toMyLinestring3D(alt: number = 0) {
    return new MyLineString3D(this.points.map((p) => MyCoordinates3D.fromMyCoordinates2D(p, alt)));
  }

  toWkx() {
    return new wkx.LineString(this.points.map((p) => p.toWkx()));
  }

  /**
   * Calculates the bounding box of the linestring.
   *
   * @returns A tuple containing two points:
   *          - The first point represents the southwest corner (minimum coordinates)
   *          - The second point represents the northeast corner (maximum coordinates)
   * @throws {Error} If the linestring is empty
   */
  getBounds(): [MyCoordinates2D, MyCoordinates2D] {
    if (this.points.length === 0) {
      throw new Error("Cannot calculate bounds of an empty linestring");
    }

    let minLng = this.points[0].lng;
    let minLat = this.points[0].lat;
    let maxLng = this.points[0].lng;
    let maxLat = this.points[0].lat;

    for (let i = 1; i < this.points.length; i++) {
      const point = this.points[i];
      minLng = Math.min(minLng, point.lng);
      minLat = Math.min(minLat, point.lat);
      maxLng = Math.max(maxLng, point.lng);
      maxLat = Math.max(maxLat, point.lat);
    }

    return [
      new MyCoordinates2D(minLng, minLat), // Southwest corner
      new MyCoordinates2D(maxLng, maxLat), // Northeast corner
    ];
  }
}
