import { A } from "@solidjs/router";
import iconUrl from "@mooovex/assets/images/mooovex_icon.svg";
import { ct } from "@mooovex/components";
import { Component } from "solid-js";
import UserInfoC from "./UserInfo.c";

const NavbarC: Component = () => {
  return (
    <>
      <nav class="navbar navbar-expand-md bg-white shadow sticky-top">
        <div class="container-fluid">
          <A class="navbar-brand" href="/">
            <img src={iconUrl} alt="Mooovex" height="30px" />
          </A>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-md-0">
              <li class="nav-item">
                <A class="nav-link" aria-current="page" href="/drivers">
                  {ct.driverlist.drivers()}
                </A>
              </li>
              <li class="nav-item">
                <A class="nav-link" aria-current="page" href="/pricelist">
                  {ct.price.pricelist()}
                </A>
              </li>
            </ul>
            <hr />
            <UserInfoC />
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavbarC;
