import { DriverlocationsProvider, TaxistationsProvider, TransferFormProvider } from "@mooovex/components";
import { MyCoordinates2D } from "@mooovex/helpers";
import { sessionInitialized, userSignal } from "@mooovex/supabase-client";
import * as Sentry from "@sentry/browser";
import { Navigate, Route, Router } from "@solidjs/router";
import { Component, Match, Show, Switch, createEffect } from "solid-js";
import NavbarWrapperC from "./components/NavbarWrapper.c";
import { OTPPage } from "./components/OTPPage";
import ResetPasswordCallbackC from "./components/ResetPasswordCallback.c";
import AccountPage from "./pages/Account.page";
import DriversPage from "./pages/Drivers.page";
import LoginPage from "./pages/Login.page";
import PricelistPage from "./pages/Pricelist.page";
import RegisterPage from "./pages/Register.page";
import ResetPasswordPage from "./pages/ResetPassword.page";
import { selectedTransportcompanyId } from "./services/state/transportcompanies.state";

const App: Component = () => {
  const canLogin = () => !!userSignal()?.confirmed_at;

  createEffect(() => Sentry.setUser(userSignal() ?? null));

  return (
    <Show when={sessionInitialized()}>
      <Router>
        <Route path="/resetPasswordCallback" component={ResetPasswordCallbackC} />
        <Switch>
          <Match when={!canLogin()}>
            <Route path="/login" component={LoginPage} />
            <Route path="/register" component={RegisterPage} />
            <Route path="/resetPassword" component={ResetPasswordPage} />
            <Route path="/resetPassword/otp" component={OTPPage} />
            <Route path="**" component={() => <Navigate href="/login" />} />
          </Match>
          <Match when={canLogin()}>
            <Route path="/" component={NavbarWrapperC}>
              <Route path="/user" component={AccountPage} />
              <Route
                path="/drivers"
                component={() => (
                  <TaxistationsProvider
                    showErrorMessage={showErrorMessage}
                    asTransportcompanyId={selectedTransportcompanyId()}
                  >
                    <DriverlocationsProvider
                      showErrorMessage={showErrorMessage}
                      viewAsTransportcompanyId={selectedTransportcompanyId()}
                    >
                      <TransferFormProvider
                        showErrorMessage={showErrorMessage}
                        getCurrentLocation={() => {
                          return new Promise((resolve, reject) => {
                            navigator.geolocation.getCurrentPosition(
                              (position) =>
                                resolve(new MyCoordinates2D(position.coords.longitude, position.coords.latitude)),
                              reject
                            );
                          });
                        }}
                        showDatePicker={() => {
                          throw new Error("Not implemented");
                        }}
                        showTimePicker={() => {
                          throw new Error("Not implemented");
                        }}
                      >
                        <DriversPage />
                      </TransferFormProvider>
                    </DriverlocationsProvider>
                  </TaxistationsProvider>
                )}
              />
              <Route path="/pricelist" component={PricelistPage} />
            </Route>
            <Route path="**" component={() => <Navigate href="/drivers" />} />
          </Match>
        </Switch>
      </Router>
    </Show>
  );
};

export default App;

function showErrorMessage(message: string) {
  alert(message);
}
